<template>
  <ToggleBlock
    id="nav"
    ref="toggleBlock"
    :active="true"
    :offset="offset"
    class="nav--toggle"
  >
    <template #default>
      <h3 id="navigation" data-test="navigation-mobile">
        {{ activeLinkText }}
      </h3>
      <SvgIcon class="nav__svg" name="chevron-down" />
    </template>
    <template #hidden>
      <nav
        class="nav--horizontal"
        data-test="nav-panel"
        aria-label="Subject level"
      >
        <a
          :href="`${urlstem}/#nav`"
          :class="{ 'nav__link--current': setCurrent(`${urlstem}/`) }"
          class="nav__link"
          data-test="nav-link"
        >
          {{ $t('component.headernavlinks.overview') }}
        </a>

        <a
          v-if="offeringType === 'course' || pathway"
          :href="`${urlstem}/entry-requirements/#nav`"
          :class="{
            'nav__link--current': setCurrent(`${urlstem}/entry-requirements/`),
          }"
          class="nav__link"
          data-test="nav-link"
        >
          {{ $t('component.headernavlinks.entry-requirements') }}
        </a>

        <a
          :href="`${urlstem}/structure/#nav`"
          :class="{ 'nav__link--current': setCurrent(`${urlstem}/structure/`) }"
          class="nav__link"
          data-test="nav-link"
        >
          {{ structureLabel }}
        </a>

        <a
          v-if="offeringType !== 'honours-specialisation'"
          :href="`${urlstem}/career-outcomes/#nav`"
          :class="{
            'nav__link--current': setCurrent(`${urlstem}/career-outcomes/`),
          }"
          class="nav__link"
          data-test="nav-link"
        >
          {{ $t('component.headernavlinks.career-outcomes') }}
        </a>

        <a
          v-if="offeringType === 'course'"
          :href="`${urlstem}/student-experience/#nav`"
          :class="{
            'nav__link--current': setCurrent(`${urlstem}/student-experience/`),
          }"
          class="nav__link"
          data-test="nav-link"
        >
          {{ $t('component.headernavlinks.student-experience') }}
        </a>

        <a
          v-if="offeringType === 'course'"
          :class="{ 'nav__link--current': setCurrent(`${urlstem}/fees/`) }"
          :href="`${urlstem}/fees/#nav`"
          class="nav__link"
          data-test="nav-link"
        >
          {{ $t('component.headernavlinks.fees-scholarships') }}
        </a>

        <a
          v-if="offeringType === 'course'"
          :class="{
            'nav__link--current': setCurrent(`${urlstem}/how-to-apply/`),
          }"
          :href="`${urlstem}/how-to-apply/#nav`"
          class="nav__link"
          data-test="nav-link"
        >
          {{ $t('component.headernavlinks.how-to-apply') }}
        </a>

        <a
          v-if="customTab"
          :href="`${urlstem}/${sluggifyCustomTab}/#nav`"
          :class="{
            'nav__link--current': setCurrent(
              `${urlstem}/${sluggifyCustomTab}/`,
            ),
          }"
          class="nav__link"
          data-test="nav-link"
        >
          {{ customTab.title }}
        </a>
      </nav>
    </template>
  </ToggleBlock>
</template>

<script>
import { ToggleBlock, SvgIcon } from '@unimelb/pattern-lib-vue';
import jump from 'jump.js';

import { sluggify } from '~/helpers/client';
import { structureTitle } from '~/helpers/navigationTitles';

export default {
  components: { ToggleBlock, SvgIcon },
  props: {
    pathway: {
      type: Boolean,
      default: false,
    },
    offeringType: {
      type: [String, Boolean],
      default: false,
    },
    category: {
      type: String,
      default: '',
    },
    root: {
      type: String,
      default: 'courses',
    },
    customTab: {
      type: Object,
      default: () => {},
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
  computed: {
    structureLabel() {
      return structureTitle(this.offeringType);
    },
    urlstem() {
      let urlpath = '';

      if (this.offeringType === 'honours-specialisation') {
        urlpath = `${this.offeringType}/${this.$route.params.slug}`;
      } else {
        urlpath = this.pathway
          ? this.$route.params.slug
          : `${this.$route.params.component}/${this.$route.params.slug}`;
      }
      return `${this.basePath}/${this.root}/${urlpath}`;
    },
    sluggifyCustomTab() {
      return sluggify(this.customTab.title);
    },
    activeLinkText() {
      const pathToTextMap = {
        '/entry-requirements': 'Entry requirements',
        '/structure': this.structureLabel,
        '/career-outcomes': 'Career outcomes',
        '/student-experience': 'Student experience',
        '/fees': 'Fees & scholarships',
        '/how-to-apply': 'How to apply',
      };
      const currentPath = this.$route.path;
      const matchingEntry = Object.entries(pathToTextMap).find(([path]) =>
        currentPath.includes(path),
      );
      return matchingEntry ? matchingEntry[1] : 'Overview';
    },
  },
  mounted() {
    if (this.$route.hash) {
      let { hash } = this.$route;
      hash = hash.replace('#', '');
      if (hash === 'nav') {
        if (this.toggleBlockActive()) {
          this.toggleIgnoreJump();
        }
        const anchor = this.$refs.toggleBlock;
        jump(anchor.$el, {
          duration: 200,
          offset: 30,
        });
      }
    }
  },
  methods: {
    setCurrent(url) {
      let curr = this.$route.path.toLowerCase();
      if (!curr.endsWith('/')) {
        curr += '/';
      }

      return curr === encodeURI(url).toLowerCase();
    },
    toggleIgnoreJump() {
      this.$refs.toggleBlock.toggle(null, true);
    },
    toggle() {
      this.$refs.toggleBlock.toggle(null);
    },
    toggleBlockActive() {
      return this.$refs.toggleBlock.getActive();
    },
  },
};
</script>
